body {
    background-color: #f2f2f2;
    background-image: url(bg3.jpg);
    background-repeat: no-repeat;
    /* background-size: 100% */
    background-attachment: fixed;
  }
  
  .container {
    margin-top: 5rem;
    padding-top: 2.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    background: transparent;
  }
  
  .excludingcontainerstyling{
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    border-top-left-radius: 0;
  }